<template>
  <b-row>
    <b-col cols="12">
      <div>
        <form-wizard
          :subtitle="null"
          :title="null"
          back-button-text="Previous"
          class="mb-3"
          color="#7367F0"
          finish-button-text="Submit"
          shape="square"
          @on-complete="formSubmitted"
        >

          <!-- Basic Info tab -->
          <tab-content
            title="Basic Information"
          >
            <b-row>
              <b-col
                class="mb-2"
                cols="12"
              >
                <h5 class="mb-0">
                  Basic Information
                </h5>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Program Type"
                  label-for="programType"
                >
                  <v-select
                    id="programType"
                    v-model="program.programType"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="['Pre-incubation Program', 'Incubation Program', 'Accelerator Program']"
                    label="Program Type"
                    placeholder="Program Type"
                  />

                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Program Name"
                  label-for="programName"
                >
                  <b-form-input
                    id="programName"
                    v-model="program.programName"

                    placeholder="Program Name"
                    type="text"
                  />

                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Startup Capacity"
                  label-for="programCapacity"
                >
                  <b-form-input
                    id="programCapacity"
                    v-model="program.programCapacity"

                    placeholder="Number of participants"
                    type="number"
                  />

                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Duration"
                  label-for="duration"
                >
                  <b-form-input
                    id="duration"
                    v-model="program.programDuration"
                    placeholder="in Months"
                    type="number"
                  />

                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Ideal Startup Lifecycle Stage"
                  label-for="lifeCycleStage"
                >
                  <v-select
                    id="lifeCycleStage"
                    v-model="program.lifeCycleStage"
                    :options="['Ideation', 'Business Modelling', 'Prototyping', 'MVP', 'Market Traction', 'Product Introduction', 'Revenue Generation', 'Scaling']"
                    append-to-body
                    multiple
                    placeholder="Select one or multiple options"
                  />

                </b-form-group>

              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Participant Industry/Sector"
                  label-for="industry"
                >
                  <v-select
                    id="industry"
                    v-model="program.industry"
                    :options="['Technology', 'Agriculture']"
                    append-to-body
                    multiple
                    placeholder="Select one or multiple options"
                  />

                </b-form-group>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
              <b-col
                cols="12"
              >
                <h5 class="mb-0">
                  Criteria
                </h5>
              </b-col>
              <b-col md="12">
                <b-row
                  v-for="(item, i) in program.criteria"
                  :key="i"
                  class="mt-2"
                >
                  <b-col>
                    <b-form-group
                      :label="`Criteria ${i + 1}`"
                      :label-for="`Criteria-${i + 1}`"
                    >
                      <b-form-input
                        :id="`Criteria-${i + 1}`"
                        v-model="item.title"

                        placeholder="Critera Title"
                        type="text"
                      />

                    </b-form-group>
                  </b-col>
                  <b-col sm="auto">
                    <b-button
                      v-if="program.criteria.length > 1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="mt-2"
                      :class="{'mr-2': i === program.criteria.length-1}"
                      variant="outline-danger"
                      @click="program.criteria.splice(index-1, 1)"
                    >
                      <feather-icon
                        class="mr-25"
                        icon="XIcon"
                      />
                    </b-button>
                    <b-button
                      v-if="i === program.criteria.length-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="mt-2"
                      variant="outline-primary"
                      @click="program.criteria.push({title: '', description: ''})"
                    >
                      <feather-icon
                        class="mr-25"
                        icon="PlusIcon"
                      />
                      <span>Add Critera</span>
                    </b-button>

                  </b-col>
                  <b-col cols="12">
                    <b-form-input
                      :id="`description-${i + 1}`"
                      v-model="item.description"
                      placeholder="Critera Description"
                      type="text"
                    />
                  </b-col>

                </b-row>
              </b-col>
              <b-col>
                <hr>
              </b-col>
              <b-col
                cols="12"
              >
                <h5 class="mb-0">
                  Deliverables
                </h5>
              </b-col>
              <b-col md="12">
                <b-row
                  v-for="(item, i) in program.deliverables"
                  :key="i"
                  class="mt-2"
                >
                  <b-col>
                    <b-form-group
                      :label="`Deliverable ${i + 1}`"
                      :label-for="`Deliverable-${i + 1}`"
                    >
                      <b-form-input
                        :id="`Deliverable-${i + 1}`"
                        v-model="item.title"

                        placeholder="Deliverable Title"
                        type="text"
                      />

                    </b-form-group>
                  </b-col>
                  <b-col sm="auto">
                    <b-button
                      v-if="program.deliverables.length > 1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="mt-2"
                      :class="{'mr-2': i === program.deliverables.length-1}"
                      variant="outline-danger"
                      @click="program.deliverables.splice(index-1, 1)"
                    >
                      <feather-icon
                        class="mr-25"
                        icon="XIcon"
                      />
                    </b-button>
                    <b-button
                      v-if="i === program.deliverables.length-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="mt-2"
                      variant="outline-primary"
                      @click="program.deliverables.push({title: '', description: ''})"
                    >
                      <feather-icon
                        class="mr-25"
                        icon="PlusIcon"
                      />
                      <span>Add Deliverable</span>
                    </b-button>

                  </b-col>
                  <b-col cols="12">
                    <b-form-input
                      :id="`description-${i + 1}`"
                      v-model="item.description"
                      placeholder="Deliverable Description"
                      type="text"
                    />
                  </b-col>

                </b-row>
              </b-col>
              <b-col>
                <hr>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label="Fundraising Assistance"
                >
                  <div class="d-flex">
                    <b-form-checkbox-group
                      v-model="program.fundraising"
                      class="d-inline"
                      disabled-field="disabled"
                      :options="[{ text: 'Demo Day', value: 'demoDay', disabled: program.fundraisingDisable},
                                 { text: 'Personal Pitching', value: 'personalPitching', disabled: program.fundraisingDisable },
                                 { text: 'Liaisoning', value: 'Liaisoning', disabled: program.fundraisingDisable }
                      ]"
                    />
                    <b-form-checkbox
                      v-model="program.fundraisingDisable"
                      value="true"
                      class="custom-control-danger"
                      @change="program.fundraisingDisable ? fundraising = [] : '' "
                    >
                      None
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </b-col>
              <b-col
                v-if="!program.fundraisingDisable"
                md="6"
              >
                <b-form-group
                  label="Ticket Size Lower limit"
                  label-for="fundRaisingSize_l"
                >
                  <b-form-input
                    id="fundRaisingSize_l"
                    v-model="program.fundRaisingSize[0]"
                    placeholder="Lower Limit"
                    type="number"
                  />

                </b-form-group>
              </b-col>
              <b-col
                v-if="!program.fundraisingDisable"
                md="6"
              >
                <b-form-group
                  label="Ticket Size Upper limit"
                  label-for="fundRaisingSize_u"
                >
                  <b-form-input
                    id="fundRaisingSize_u"
                    v-model="program.fundRaisingSize[1]"
                    placeholder="Upper Limit"
                    type="number"
                  />

                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Inhouse Funds"
                  label-for="inhouseFunds"
                >
                  <b-form-input
                    id="inhouseFunds"
                    v-model="program.inhouseFunds"
                    placeholder="Total Inhouse Funds"
                    type="number"
                  />

                </b-form-group>
              </b-col>
              <b-col
                v-if="program.inhouseFunds > 0"
                md="4"
              >
                <b-form-group
                  label="Inhouse Funds Lower limit"
                  label-for="inhouseFundsSize_l"
                >
                  <b-form-input
                    id="inhouseFundsSize_l"
                    v-model="program.inhouseFundsSize[0]"
                    placeholder="Lower Limit"
                    type="number"
                  />

                </b-form-group>

                <b-form-group
                  label="Inhouse Funds Upper limit"
                  label-for="inhouseFundsSize_u"
                >
                  <b-form-input
                    id="inhouseFundsSize_u"
                    v-model="program.inhouseFundsSize[1]"
                    placeholder="Upper Limit"
                    type="number"
                  />

                </b-form-group>
              </b-col>
            </b-row>
          </tab-content>

          <!-- Phase Structure tab  -->
          <tab-content
            title="Phase Structure"
          >
            <b-row>
              <b-col
                class="mb-2"
                cols="12"
              >
                <h5 class="mb-0">
                  Phase Structure
                </h5>
              </b-col>

            </b-row>
            <b-row
              v-for="(phase, index) in program.phases"
              :id="phase.id"
              :key="phase.id"
              class="shadow rounded py-1 mb-2 mx-25"
            >

              <!-- Item Name -->
              <b-col md="5">
                <b-form-group
                  :label="`Phase ${index+1}`"
                  :label-for="'phase-'+phase.id"
                >
                  <b-form-input
                    :id="'phase-'+phase.id"
                    v-model="phase.title"
                    class="mb-1"
                  />
                </b-form-group>
              </b-col>
              <b-col md="7">
                <b-form-group
                  label="Description"
                  :label-for="'phaseDescription-'+phase.id"
                >
                  <b-form-input
                    :id="'phaseDescription-'+phase.id"
                    v-model="phase.description"
                    type="text"
                    class="mb-1"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="12"
              >
                <b-row
                  v-for="(objective, i) in phase.objectives"
                  :id="objective.id"
                  :key="objective.id"
                  class="mx-25 border-left"
                >

                  <!-- Item Name -->
                  <b-col md="5">
                    <b-form-group
                      :label="`Objective ${i+1}`"
                      :label-for="'objective-'+objective.id"
                    >
                      <b-form-input
                        :id="'objective-'+objective.id"
                        v-model="objective.title"
                        class="mb-1"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="7">
                    <b-form-group
                      label="Description"
                      :label-for="'objectiveDescription-'+objective.id"
                    >
                      <b-form-input
                        :id="'objectiveDescription-'+objective.id"
                        v-model="objective.description"
                        type="text"
                        class="mb-1"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="12"
                  />

                  <!-- Buttons -->
                  <b-col
                    class="mb-50"
                  >
                    <b-button
                      v-if="phase.objectives.length-1 == i"
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      size="sm"
                      class="mt-0 mr-1"
                      variant="outline-primary"
                      @click="phase.objectives.push(
                        {
                          title: '',
                          description: '',
                        })"
                    >
                      <feather-icon
                        class="mr-25"
                        icon="PlusIcon"
                      />
                      <span>Add Objective</span>
                    </b-button>
                    <b-button
                      v-if="phase.objectives.length > 1"
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      size="sm"
                      class="mt-0"
                      variant="outline-danger"
                      @click="phase.objectives.splice(i, 1)"
                    >
                      <feather-icon
                        class="mr-25"
                        icon="XIcon"
                      />
                      <span>Remove Objective</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </b-col>

              <!-- Buttons -->
              <b-col
                class="mb-50"
              >
                <b-button
                  v-if="program.phases.length -1 == index"
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  class="mt-0 mr-1 mt-md-2 btn-icon"
                  variant="outline-primary"
                  @click="program.phases.push(
                    {
                      title: '',
                      description: '',
                      objectives: [
                        {
                          title: '',
                          description: '',
                        },
                      ],
                      subphases: [{
                        title: '',
                        description: '',
                        days: [
                          {
                            activities: [
                              {
                                title: '',
                                type: '',
                                duration: '',
                                description: '',
                                objectives: [
                                  {
                                    title: '', description: '',
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      }],
                    },)"
                >
                  <feather-icon
                    class="mr-25"
                    icon="PlusIcon"
                  />
                  <span>Add Phase</span>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  class="mt-0 mt-md-2 btn-icon"
                  variant="outline-danger"
                  :disabled="program.phases.length < 2"
                  @click="program.phases.splice(index, 1)"
                >
                  <feather-icon
                    class="mr-25"
                    icon="XIcon"
                  />
                  <span>Remove Phase</span>
                </b-button>
              </b-col>
            </b-row>
          </tab-content>

          <!-- Phase Details tab  -->
          <tab-content
            title="Phase Details"
          >
            <b-row>
              <b-col
                class="mb-2"
                cols="12"
              >
                <h5 class="mb-0">
                  Phase Details
                </h5>
              </b-col>

            </b-row>
            <b-tabs
              pills
            >
              <b-tab
                v-for="(phase, i) in program.phases"
                :key="i"
                :title="phase.title"
                active
              >
                <b-card-text>
                  <b-row
                    v-for="(subphase, index) in phase.subphases"
                    :id="subphase.id"
                    :key="subphase.id"
                    class="shadow rounded py-1 mb-2 mx-25"
                  >

                    <!-- Item Name -->
                    <b-col md="5">
                      <b-form-group
                        :label="`Subphase ${index+1}`"
                        :label-for="'subphase-'+subphase.id"
                      >
                        <b-form-input
                          :id="'subphase-'+subphase.id"
                          v-model="subphase.title"
                          class="mb-1"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="7">
                      <b-form-group
                        label="Description"
                        :label-for="'subphaseDescription-'+subphase.id"
                      >
                        <b-form-input
                          :id="'subphaseDescription-'+subphase.id"
                          v-model="subphase.description"
                          type="text"
                          class="mb-1"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      md="12"
                    >
                      <b-row
                        v-for="(day, j) in subphase.days"
                        :key="j"
                        class="mx-25 border-left"
                      >
                        <b-col
                          cols="auto"
                          class="mb-1"
                        >
                          <span class="h5 mr-1">
                            Day {{ j+1 }}
                          </span>
                          <b-button
                            v-if="subphase.days.length > 1"
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            size="sm"
                            class="mt-0"
                            variant="outline-danger"
                            @click="subphase.days.splice(j, 1)"
                          >
                            <feather-icon
                              class="mr-25"
                              icon="XIcon"
                            />
                            <span>Remove Day</span>
                          </b-button>
                        </b-col>
                        <b-col cols="12">

                          <app-timeline>
                            <app-timeline-item
                              v-for="(activity, k) in day.activities"
                              :key="k"
                            >
                              <b-row><!-- Item Name -->
                                <b-col md="6">
                                  <b-form-group
                                    :label="`Activity Title`"
                                    :label-for="'activity-'+activity.id"
                                  >
                                    <b-form-input
                                      :id="'activity-'+activity.id"
                                      v-model="activity.title"
                                      class="mb-1"
                                    />
                                  </b-form-group>
                                </b-col>
                                <b-col md="6">
                                  <b-form-group
                                    :label="`Activity type`"
                                    :label-for="'activityType-'+activity.id"
                                  >
                                    <b-form-input
                                      :id="'activityType-'+activity.id"
                                      v-model="activity.type"
                                      class="mb-1"
                                    />
                                  </b-form-group>
                                </b-col>
                                <b-col md="6">
                                  <b-form-group
                                    :label="`Activity duration`"
                                    :label-for="'activityDuration-'+activity.id"
                                  >
                                    <b-form-input
                                      :id="'activityDuration-'+activity.id"
                                      v-model="activity.duration"
                                      class="mb-1"
                                    />
                                  </b-form-group>
                                </b-col>
                                <b-col md="6">
                                  <b-form-group
                                    :label="`Activity description`"
                                    :label-for="'activityDesc-'+activity.id"
                                  >
                                    <b-form-input
                                      :id="'activityDesc-'+activity.id"
                                      v-model="activity.description"
                                      class="mb-1"
                                    />
                                  </b-form-group>
                                </b-col>
                                <b-col
                                  cols="12"
                                  class="border-left mx-1"
                                >
                                  <h5>Objectives</h5>
                                  <b-row
                                    v-for="(objective, l) in activity.objectives"
                                    :key="l"
                                  >
                                    <!-- Item Name -->
                                    <b-col md="5">
                                      <b-form-group
                                        :label="`Objective ${l+1}`"
                                        :label-for="'objective-'+objective.id"
                                      >
                                        <b-form-input
                                          :id="'objective-'+objective.id"
                                          v-model="objective.title"
                                          class="mb-1"
                                        />
                                      </b-form-group>
                                    </b-col>
                                    <b-col md="7">
                                      <b-form-group
                                        label="Description"
                                        :label-for="'objectiveDescription-'+objective.id"
                                      >
                                        <b-form-input
                                          :id="'objectiveDescription-'+objective.id"
                                          v-model="objective.description"
                                          type="text"
                                          class="mb-1"
                                        />
                                      </b-form-group>
                                    </b-col>
                                    <b-col
                                      md="12"
                                    />
                                    <!-- Buttons -->
                                    <b-col
                                      class="mb-50"
                                    >
                                      <b-button
                                        v-if="activity.objectives.length-1 == l"
                                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                        size="sm"
                                        class="mt-0 mr-1"
                                        variant="outline-primary"
                                        @click="activity.objectives.push(
                                          {
                                            title: '',
                                            description: '',
                                          })"
                                      >
                                        <feather-icon
                                          class="mr-25"
                                          icon="PlusIcon"
                                        />
                                        <span>Add Objective</span>
                                      </b-button>
                                      <b-button
                                        v-if="activity.objectives.length > 1"
                                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                        size="sm"
                                        class="mt-0"
                                        variant="outline-danger"
                                        @click="activity.objectives.splice(l, 1)"
                                      >
                                        <feather-icon
                                          class="mr-25"
                                          icon="XIcon"
                                        />
                                        <span>Remove Objective</span>
                                      </b-button>
                                    </b-col>
                                  </b-row></b-col>
                                <b-col
                                  class="mb-50 pt-1"
                                >
                                  <b-button
                                    v-if="day.activities.length-1 == k"
                                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                    size="sm"
                                    class="mt-0 mr-1"
                                    variant="primary"
                                    @click="day.activities.push(
                                      {
                                        title: '',
                                        type: '',
                                        duration: '',
                                        description: '',
                                        objectives: [
                                          {
                                            title: '', description: '',
                                          },
                                        ],
                                      })"
                                  >
                                    <feather-icon
                                      class="mr-25"
                                      icon="PlusIcon"
                                    />
                                    <span>Add Activity</span>
                                  </b-button>
                                  <b-button
                                    v-if="day.activities.length > 1"
                                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                    size="sm"
                                    class="mt-0"
                                    variant="danger"
                                    @click="day.activities.splice(k, 1)"
                                  >
                                    <feather-icon
                                      class="mr-25"
                                      icon="XIcon"
                                    />
                                    <span>Remove Activity</span>
                                  </b-button>
                                </b-col>
                              </b-row>
                            </app-timeline-item>
                          </app-timeline>

                        </b-col>
                        <b-col
                          v-if="subphase.days.length-1 == j"
                          cols="12"
                          class="mb-50 pt-1"
                        >
                          <b-button
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            size="sm"
                            class="mt-0 mr-1"
                            variant="outline-primary"
                            @click="subphase.days.push(
                              {
                                activities: [
                                  {
                                    title: '',
                                    type: '',
                                    duration: '',
                                    description: '',
                                    objectives: [
                                      {
                                        title: '', description: '',
                                      },
                                    ],
                                  },
                                ],
                              })"
                          >
                            <feather-icon
                              class="mr-25"
                              icon="PlusIcon"
                            />
                            <span>Add Day</span>
                          </b-button>
                        </b-col>
                        <b-col cols="12">
                          <hr>
                        </b-col>
                      </b-row>
                    </b-col>

                    <!-- Buttons -->
                    <b-col
                      class="mb-50"
                    >
                      <b-button
                        v-if="phase.subphases.length -1 == index"
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        class="mt-0 mr-1 mt-md-2 btn-icon"
                        variant="outline-primary"
                        @click="phase.subphases.push(
                          {
                            title: '',
                            description: '',
                            days: [
                              {
                                activities: [
                                  {
                                    title: '',
                                    type: '',
                                    duration: '',
                                    description: '',
                                    objectives: [
                                      {
                                        title: '', description: '',
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          })"
                      >
                        <feather-icon
                          class="mr-25"
                          icon="PlusIcon"
                        />
                        <span>Add Subphase</span>
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        class="mt-0 mt-md-2 btn-icon"
                        variant="outline-danger"
                        :disabled="phase.subphases.length < 2"
                        @click="phase.subphases.splice(index, 1)"
                      >
                        <feather-icon
                          class="mr-25"
                          icon="XIcon"
                        />
                        <span>Remove Subphase</span>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </tab-content>

        </form-wizard>

      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton, BCol, BFormGroup, BFormInput, BRow, BFormCheckbox, BFormCheckboxGroup, BTabs, BTab,
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { email, required } from '@core/utils/validations/validations'
import gql from 'graphql-tag'
// import FormBuilder from './components/FormBuilder.vue'
// import ObjectiveForm from './components/ObjectiveForm.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
// import AddProgram from './components/gql/AddProgram.gql'
// import AddCriterion from './components/gql/AddCriterion.gql'

export default {
  components: {
    FormWizard,
    TabContent,
    AppTimeline,
    AppTimelineItem,
    BTabs,
    BTab,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BButton,
    BFormCheckbox,
    BFormCheckboxGroup,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      programValues: '',
      inputVariable: {
        criteriaTable: [],
        deliverableTable: [],
        roundsTable: [],
        phaseTable: [],
        sectionTest: [],
      },
      program: {
        programName: '',
        programId: '',
        programType: '',
        programDuration: '',
        programCapacity: '',
        lifeCycleStage: [],
        industry: '',
        criteria: [
          {
            title: '',
            description: '',
          },
        ],
        deliverables: [
          {
            title: '',
            description: '',
          },
        ],
        fundraisingDisable: false,
        fundraising: [],
        fundRaisingSize: [],
        inhouseFunds: '',
        inhouseFundsSize: [],
        phases: [
          {
            title: '',
            description: '',
            objectives: [
              {
                title: '',
                description: '',
              },
            ],
            subphases: [{
              title: '',
              description: '',
              days: [
                {
                  activities: [
                    {
                      title: '',
                      type: '',
                      duration: '',
                      description: '',
                      objectives: [
                        {
                          title: '', description: '',
                        },
                      ],
                    },
                  ],
                },
              ],
            }],
          },
        ],
      },
      required,
      email,
    }
  },
  watch: {
    programValues(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setProgramModel()
      }
    },
    phaseValues(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setPhaseModel()
      }
    },
  },
  methods: {
    formSubmitted() {
      if (!this.$route.params.id) {
        this.addProgram()
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    addProgram() {
      this.setVariables()
      this.$apollo.mutate({
      // Query
        mutation: gql`mutation (
            $title: String!,
            $type: String!,
            $industry: String!,
            $ideal_lifecycle_stage: String!,
            $fund_raising_assistance: String!,
            $ticket_size_of_fund_raised: String!,
            $capacity: Int!,
            $inhouse_funds: String!,
            $inhouse_ticket_size: String!,
            $criteriaInput: [programs_shortlistingcriteriontable_insert_input!]!,
            $deliverableInput: [programs_deliverablelisttable_insert_input!]!,
            $roundInput: [programs_applicationformstable_insert_input!]!,
        ) {
          insert_programs_basicinfo(objects: [{
            title: $title,
            type: $type,
            status: "on",
            industry: $industry,
            ideal_lifecycle_stage: $ideal_lifecycle_stage,
            fund_raising_assistance: $fund_raising_assistance,
            ticket_size_of_fund_raised: $ticket_size_of_fund_raised,
            capacity: $capacity,
            inhouse_funds: $inhouse_funds,
            inhouse_ticket_size: $inhouse_ticket_size
            programs_shortlistingcriteriontables: {
              data: $criteriaInput
            }
            programs_deliverablelisttables: {
              data: $deliverableInput
            }
            programs_applicationformstables: {
              data: $roundInput
            }
            }])
            {
            returning{
              id
              title
            }
          }
        }`,
        variables: {
          title: this.program.programName,
          type: this.program.programType,
          industry: this.program.industry.toString(),
          ideal_lifecycle_stage: this.program.lifeCycleStage.toString(),
          fund_raising_assistance: this.program.fundraising.toString(),
          ticket_size_of_fund_raised: this.program.fundRaisingSize.toString(),
          capacity: parseInt(this.program.programCapacity, 10),
          inhouse_funds: this.program.inhouseFunds,
          inhouse_ticket_size: this.program.inhouseFundsSize.toString(),
          criteriaInput: this.program.criteria,
          deliverableInput: this.program.deliverables,
          roundInput: this.inputVariable.roundsTable,
        },
      }).then(data => {
      // Result
        this.programId = data.data.insert_programs_basicinfo.returning[0].id
        this.addPhase()
      })
      this.resetValues()
      return this.error
    },
    addPhase() {
      for (let i = 0; i < this.program.phases.length; i += 1) {
        this.inputVariable.phaseTable.push({ program_id: parseInt(this.programId, 10), operations_type: 'Phase', title: this.program.phases[i].title })
      }
      this.$apollo.mutate({
      // Query
        mutation: gql`mutation (
            $phaseInput: [programs_operationstable_insert_input!]!
        ) {
          insert_programs_operationstable(objects: $phaseInput)
            {
              returning{
                id
              }
            }
        }`,
        variables: {
          phaseInput: this.inputVariable.phaseTable,
        },
      })
    },
    setVariables() {
      for (let i = 0; i < this.program.rounds.length; i += 1) {
        this.inputVariable.roundsTable.push({
          title: this.program.rounds[i].title,
          round_number: i + 1,
          lprograms_applicationsquestionstables: { data: [] },
          programs_applicationreviewquestionnairetables: { data: [] },
        })
        for (let j = 0; j < this.program.rounds[i].applicationForm.length; j += 1) {
          for (let k = 0; k < this.program.rounds[i].applicationForm[j].items.length; k += 1) {
            this.inputVariable.roundsTable[i].programs_applicationsquestionstables.data.push(
              {
                question: this.program.rounds[i].applicationForm[j].items[k].question,
                section: this.program.rounds[i].applicationForm[j].title,
                question_number: k + 1,
                answer_type: this.program.rounds[i].applicationForm[j].items[k].dataType,
              },
            )
          }
        }
        for (let j = 0; j < this.program.rounds[i].reviewForm.length; j += 1) {
          for (let k = 0; k < this.program.rounds[i].reviewForm[j].items.length; k += 1) {
            this.inputVariable.roundsTable[i].programs_applicationreviewquestionnairetables.data.push(
              {
                question: this.program.rounds[i].reviewForm[j].items[k].question,
                section: this.program.rounds[i].reviewForm[j].title,
                question_number: k + 1,
                answer_type: this.program.rounds[i].reviewForm[j].items[k].dataType,
              },
            )
          }
        }
      }
    },
    resetValues() {
      this.inputVariable.roundsTable = []
    },
    setProgramModel() {
      if (this.$route.params.id) {
        this.program.programName = this.programValues.title
        this.program.programType = this.programValues.type
        this.program.programCapacity = this.programValues.capacity
        this.program.lifeCycleStage = (this.programValues.ideal_lifecycle_stage) ? this.programValues.ideal_lifecycle_stage.split(',') : []
        this.program.industry = (this.programValues.industry) ? this.programValues.industry.split(',') : ''
        for (let i = 0; i < this.programValues.programs_shortlistingcriteriontables.length; i += 1) {
          this.program.criteria.push(this.programValues.programs_shortlistingcriteriontables[i].criteria)
        }
        for (let i = 0; i < this.programValues.programs_deliverablelisttables.length; i += 1) {
          this.program.deliverables.push(this.programValues.programs_deliverablelisttables[i].deliverable)
        }
        this.program.fundraising = (this.programValues.fund_raising_assistance) ? this.programValues.fund_raising_assistance.split(',') : []
        this.program.fundRaisingSize = (this.programValues.ticket_size_of_fund_raised) ? this.programValues.ticket_size_of_fund_raised.split(',') : []
        this.program.inhouseFunds = this.programValues.inhouse_funds
        this.program.inhouseFundsSize = (this.programValues.inhouse_ticket_size) ? this.programValues.inhouse_ticket_size.split(',') : []
        for (let i = 0; i < this.programValues.programs_applicationformstables.length; i += 1) {
          this.program.rounds[i].title = this.programValues.programs_applicationformstables[i].title
          if (i !== this.programValues.programs_applicationformstables.length - 1) {
            this.program.rounds.push(
              {
                title: '',
                applicationForm: '',
                reviewForm: '',
              },
            )
          }
          this.inputVariable.sectionTest = [...new Set(this.programValues.programs_applicationformstables[i].programs_applicationsquestionstables.map(x => x.section))]
        }
      }
    },
    setPhaseModel() {
      if (this.$route.params.id) {
        for (let i = 0; i < this.phaseValues.length; i += 1) {
          this.program.phases[i].title = this.phaseValues[i].title
          if (i !== this.phaseValues.length - 1) {
            this.program.phases.push(
              {
                title: '',
                duration: '',
                objectiveForm: '', // Refer to ObjectiveForm.vue for structure
              },
            )
          }
        }
      }
    },
  },
  apollo: {
    programValues: {
      query() {
        return gql`
        {
          programs_basicinfo(where: {id: {_eq: ${this.$route.params.id}}}) {
            id,
            title,
            type,
            capacity,
            ideal_lifecycle_stage,
            industry,
            programs_shortlistingcriteriontables{
              criteria
            },
            programs_deliverablelisttables{
              deliverable
            }
            fund_raising_assistance,
            ticket_size_of_fund_raised,
            inhouse_funds,
            inhouse_ticket_size,
            programs_applicationformstables {
              round_number
              title
              programs_applicationsquestionstables {
                answer_type
                question
                section
              }
              programs_applicationreviewquestionnairetables {
                answer_type
                question
                section
              }
            }
          }
        }`
      },
      update: data => data.programs_basicinfo[0],
    },
    phaseValues: {
      query() {
        return gql`
        {
          programs_operationstable(where: {program_id: {_eq: ${this.$route.params.id}}}) {
            title
          }
        }`
      },
      update: data => data.programs_operationstable,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
